<template>
  <span class="dictionary">
    <el-cascader
      :size="size"
      ref="elCascader"
      v-model="val"
      :clearable="clearable"
      :disabled="disabled"
      :placeholder="placeholder"
      :options="options"
      :filterable="filterable"
      :props="cascaderProps"
      @visible-change="visibleChange"
      @change="optionChange"
      @expand-change="expandChange"
    >
    </el-cascader>
  </span>
</template>

<script>
import { initTreeData } from '@/util/common'
import api from '../api/index'
export default {
  name: 'SubjectCascader',
  components: {},
  props: {
    filterable: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    clearable: {
      //是否显示清空按钮
      type: Boolean,
      default: true,
    },
    checkStrictly: {
      //是否可以选择分支节点
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '请选择',
    },
    valKey: {
      type: String,
      default: 'dictVal',
    },
    expandTrigger: {
      type: String,
      default: 'hover',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: 'dictName',
    },
    code: {
      type: String,
    },
    value: {},
    size: {
      type: String,
      default: '',
    },
    Options: {
      type: Array,
      default: function () {
        return []
      },
    },
  },
  data() {
    return {
      that: this,
      val: [],
      options: this.Options || [],
      cascaderProps: {
        lazy: true,
        multiple: this.multiple,
        expandTrigger: this.expandTrigger,
        checkStrictly: this.checkStrictly,
        value: this.valKey,
        label: this.label,
        emitPath: true,
        async lazyLoad(node, resolve) {
          // 通过调用resolve将子节点数据返回，通知组件数据加载完成
          const { level, value } = node
          if (value && level == 1) {
            const res = await api.dict.listSysDictData(value, true)
            res.data.forEach(v => {
              v.disabled = false
            })
            resolve(res.data)
          }
        },
      },
      currentLevel: null,
    }
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  watch: {
    code: {
      immediate: true, //初始化立即执行
      handler: function (newVal) {
        if (newVal) {
          this.init()
        }
      },
    },
    value: {
      immediate: true, //初始化立即执行
      handler: function (newVal) {
        if (this.multiple) {
          if (newVal) {
            this.val = newVal.split(',')
          }
        } else {
          this.val = newVal
        }
      },
    },
  },
  mounted() {},
  methods: {
    expandChange(event) {
      this.currentLevel = event.length
      let that = this
      setTimeout(() => {
        that.setCascaderDomEvent()
      }, 500)
    },
    // 绑定子菜单label的click监听
    setCascaderDomEvent() {
      let cascaderDom = document.querySelectorAll('.special-cascader .el-cascader-menu__list')
      if (cascaderDom.length >= this.currentLevel - 1) {
        let optionDom = cascaderDom[this.currentLevel]
        if (optionDom) {
          optionDom.querySelectorAll('.el-cascader-node__label').forEach(label => {
            label.addEventListener('click', this.cascaderCheckEvent)
          })
        }
      }
    },
    // 子菜单label监听事件方法
    cascaderCheckEvent(event) {
      let brother = event.target.previousElementSibling
      const input = brother.querySelector('input[type="radio"]')
      if (input) {
        input.click() // 模拟点击
      }
    },
    init() {
      this.$api.dict
        .listSysDictData(this.code, true)
        .then(res => {
          this.options = res.data
          this.$emit('optionsChange', this.options)
        })
        .catch(err => {
          console.log(err)
        })
    },
    visibleChange(v) {
      if (v) {
        const container = document.querySelectorAll('.el-scrollbar__bar')
        container.forEach(item => {
          item.style.opacity = '1'
        })
      }
    },
    optionChange(v) {
      this.$emit('change', v)
      if (this.$refs.elCascader) {
        this.$refs.elCascader.dropDownVisible = false //监听值发生变化就关闭它
      }
    },
  },
}
</script>

<stlye lang="scss">
.el-cascader-panel .el-radio {
  width: 100%;
  height: 100%;
  z-index: 10;
  position: absolute;
  top: 10px;
  right: 10px;
}
.el-cascader-panel .el-radio__input {
  visibility: hidden !important;
}
.el-cascader-panel .el-cascader-node__postfix {
  top: 10px;
}
.el-cascader-panel .el-icon-loading {
  display: none;
}
</stlye>
